<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
              style="text-transform:uppercase;"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
              style="text-transform:uppercase;"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :number-hidden="condocumento"
            :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
          />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              ref="fecha_nacimiento"
              v-model="formulario1.fecha_nacimiento"
              type="date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form1_persona.UY.telefono')"
            :error="errors.get('telefono')"
          >
            <el-input
              ref="telefono"
              v-model="formulario1.telefono"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_ingreso"
            :label="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            :error="errors.get('fecha_ingreso')"
          >
            <el-date-picker
              ref="fecha_ingreso"
              v-model="formulario1.fecha_ingreso"
              type="date"
              :picker-options="pickerOptions1"
              :disabled="isjubilado"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="relacion_laboral_id"
            :label="$t('forms_flujo.form2_persona.BNF.tipo_trabajo')"
          >
            <el-select
              ref="relacion_laboral_id"
              v-model="formulario1.relacion_laboral_id"
              clearable
              filterable
              :placeholder="$t('forms_flujo.form2_persona.BNF.tipo_trabajo')"
            >
              <el-option
                v-for="item in relacion_laboral"
                :key="item.id"
                :label="item.relacion"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.BNF.tipo_trabajo')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="empresa_oferante"
            :label="$t('forms_flujo.form1_persona.UY.empresa_oferante')"
            :error="errors.get('empresa_oferante')"
          >
            <el-autocomplete
                    class="inline-input"
                    ref="empresa_oferante"
                    v-model="formulario1.empresa_oferante"
                    :fetch-suggestions="getSuggest"
                    :placeholder="$t('forms_flujo.form1_persona.UY.empresa_oferante')"
                    :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="tipo_vehiculo"
                  :label="$t('forms_flujo.form1_persona.UY.tipo_vehiculo')"
                  :error="errors.get('tipo_vehiculo')"
          >
            <el-input
                    ref="tipo_vehiculo"
                    v-model="formulario1.tipo_vehiculo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.tipo_vehiculo')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required, EspacioBlanco} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {
    getRelacionesLaborales,
    getEmpresasOferentes
} from "@/routes/api/resources";
import { validateContact,validateNames} from "@/routes/api/resources";
import {isEmpty} from "@/commons/utils/functions";
export default {
    name: 'PzPersonForm1Vehiculo',
    components: {  PzFormFlujo, PzIdentityDocument},
    mixins: [PzBaseForm1],
    data() {
        var check_Fecha = (rule, value, callback) => {

            localStorage.setItem('isjubilado',true)
            if (this.formulario1.relacion_laboral_id!=4)
                localStorage.setItem('isjubilado',false)

            if (this.formulario1.relacion_laboral_id!=4 && !value) {
                return callback(new Error(this.$t('rules.incomplete')));
            }

            return callback();
        };
        var check_telefono = (rule, value, callback) => {
          var regla = "^[+]?[0-9]{10}$";
        
          let pattern = new RegExp(regla.toString());
          if (!pattern.test(value)) {
            return callback(new Error(this.$t('rules.phone_rule')));
           
          }else{
              this.$store.dispatch('loader/up', {trigger: this.$options.name})
                validateContact(null, this.formulario1.telefono, this.formulario1.identity.number).then((res) => {                  
                    if (res.data.phone_exists) {
                      return callback(new Error(this.$t('rules.phone_exists')));
                    } else {
                      return callback();
                    }
                }).catch(() => {
                  return callback(new Error(this.$t('rules.phone_exists')));
                }).finally(() => {
                    this.$store.dispatch('loader/down',{trigger:this.$options.name})
                });
        }

      };
      var check_name = (rule, value, callback) => {         
        if (!(value)) {
          return callback(new Error(this.$t('rules.name_required')));
        }else{
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
          validateNames(1, this.formulario1.nombres, this.formulario1.identity.number).then((res) => {
              if (!res.data.correct) {
                return callback(new Error(this.$t('rules.name_rule')));
              } else {
                return callback();
              }
          }).catch(() => {
            return callback(new Error(this.$t('rules.name_rule')));
          }).finally(() => {
              this.$store.dispatch('loader/down',{trigger:this.$options.name})
          });
      }      

      };
      var check_lastname = (rule, value, callback) => {  
        if (!(value)) {
          return callback(new Error(this.$t('rules.lastname_required')));
        }else{
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
          validateNames(2, this.formulario1.apellidos, this.formulario1.identity.number).then((res) => {
              if (!res.data.correct) {
                return callback(new Error(this.$t('rules.lastname_rule')));
              } else {
                return callback();
              }
          }).catch(() => {
            return callback(new Error(this.$t('rules.lastname_rule')));
          }).finally(() => {
              this.$store.dispatch('loader/down',{trigger:this.$options.name})
          });
      }
      };
        var check_Empresa = (rule, value, callback) => {
            if (!(value)) {
                return callback(new Error(this.$t('rules.empresa_oferente_required')));
            }else{
                this.$store.dispatch('loader/up', {trigger: this.$options.name})
                let suggest = this.suggest;
                let results =  suggest.filter(function (empresa) {
                    return (empresa.value).toLowerCase()==value.toLowerCase();
                });
                this.$store.dispatch('loader/down',{trigger:this.$options.name})
                if(results.length==0) {
                  //  this.formulario1.empresa_oferante = '';
                    return callback(new Error(this.$t('rules.empresa_oferente_invalido')));
                }

                return callback();
            }
        };
        return {
            reference: 'formulario1',
            sucursales: [],
            relacion_laboral: [ ],
            empresas_oferentes: [ ],
            suggest: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                telefono: '',
                fecha_nacimiento: '',
                identity: {document: 1},
                type_prestamo:'',
                consulta_clearing:0,
                consulta_bcu:0,
                fecha_ingreso: '',
                relacion_laboral_id:'',
                tipo_vehiculo:'',
                empresa_oferante:'',

            },
            view_sucursal : false,
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco()],
                nombres: [{validator: check_name, trigger: 'blur'}],
                apellidos: [{validator: check_lastname, trigger: 'blur'}],
                telefono: [{validator: check_telefono, trigger: 'blur'}],
                fecha_nacimiento: [required('')],
                fecha_ingreso: [{validator: check_Fecha, trigger: 'blur'}],
                relacion_laboral_id: [required('')],
                tipo_vehiculo: [required('')],
                empresa_oferante: [{validator: check_Empresa, trigger: 'change'}],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    computed:{
      isjubilado(){
          return (this.formulario1.relacion_laboral_id && this.formulario1.relacion_laboral_id=='4')
      },
        condocumento(){
            var docmun = localStorage.getItem('docnum');
            if(docmun){
                return    true;
            }

            return false
        }
    },
    created: function () {
        this.$store.dispatch('loader/up', {trigger: this.$options.name})
        var docmun = localStorage.getItem('docnum');
        if(docmun)
          this.formulario1.identity.number = docmun;
        var re = getRelacionesLaborales().then((res) => {
            this.relacion_laboral = res.data;
        }).catch(() => {
            this.relacion_laboral = [];
        });
        var emp = getEmpresasOferentes().then((res) => {
            this.empresas_oferentes = res.data;
        }).catch(() => {
            this.empresas_oferentes = [];
        });


        Promise.all([ re, emp]).then(() => {
            this.suggest = Object.values( this.empresas_oferentes).map((value) => {
                return {value: value}
            })

        }).finally(() => {
            this.$store.dispatch('loader/down',{trigger:this.$options.name})
        });
    },
    methods: {
        getSuggest(queryString, cb) {
            let suggest = this.suggest;
            const self = this;
            let results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
                return (self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0);
            }) : suggest;
            cb(results);
        },

    },


}
</script>

